import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import React, { useEffect, useState } from "react";
import { login } from "../api/User";
import sea from "../img/sea.jpg";
import AuthCardWrapper from '../ui-component/AuthCardWrapper';
import AuthWrapper1 from '../ui-component/AuthWrapper1';

const MyTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#0BD8A0',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black', // Change outline border color here
    },
    '&:hover fieldset': {
      borderColor: '#0BD8A0', // Change outline border color on hover here
    },
    '&.Mui-focused fieldset': {
      borderColor: '#0BD8A0', // Change outline border color on focus here
    }
  },
});

const MyButton = styled(Button)({
  '&:hover': {
    backgroundColor: '#16A788', // Change hover color here
  },
});

export default function LoginPage({ setToken }) {

  useEffect(() => {
    console.log(process.env.NODE_ENV)
  }, [])

  const [mobileNum, setMobileNum] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    console.log(process.env.NODE_ENV)
  }, [])

  const checkUserCredentials = async (event) => {
    event.preventDefault();
    var credentials = {
      "number": mobileNum,
      "password": password
    }
    let accountFound = await login(credentials)
    if (accountFound != null) {
      setErrorMessage("")
      setToken(accountFound)
    } else {
      setErrorMessage("Invalid Mobile number or password")
    }
  }

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AuthWrapper1>
      <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh',backgroundImage: `url(${sea})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center' }}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar sx={{ m: 1, bgcolor: '#0BD8A0' }}>
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h5">
                        Sign in
                      </Typography>
                      <Box component="form" noValidate onSubmit={checkUserCredentials} sx={{ mt: 1 }}>
                        <MyTextField
                          margin="normal"
                          variant="outlined"
                          required
                          fullWidth
                          id="number"
                          label="Mobile Number"
                          name="number"
                          autoFocus
                          onChange={(e) => setMobileNum(e.target.value)}
                        />
                        <MyTextField
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <MyButton
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2, bgcolor: '#0BD8A0' }}
                        >
                          Sign In
                        </MyButton>
                        <Grid container>
                          <Grid item xs={12}>
                            {errorMessage &&
                              <Alert variant="outlined" severity="error">
                                {errorMessage}
                              </Alert >
                            }
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper1>
  );
}