import { Outlet } from "react-router-dom";
import Sidebar from "../components/SideBar";
import NavBar from "../components/Navbar";


const HomeLayout = ({ setToken }) => {
  return (
    <>
      <div class="flex flex-row">
      <div>  
      <Sidebar setToken={setToken}/>
      </div>
        <div class="flex flex-col w-full">
          <NavBar />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default HomeLayout;