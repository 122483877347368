const baseURL = process.env.REACT_APP_API
export async function getChits(){
    let chits = null
    const response = await fetch(baseURL+"/chit/getChits")
    if(response.status === 200){
        chits = await response.json().then((chits) => chits)
    }
    return chits
}
export async function addChit(chit){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(chit)
    };
    const response = await fetch(baseURL+"/chit/addChit",requestOptions)
    return response
}

export async function linkUser(request){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };
    console.log(request)
    const response = await fetch(baseURL+"/chit/linkUser",requestOptions)
    return response
}