import { Typography } from "@mui/material";
import { useState } from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import Modal from "./PopupModal";

export default function SideBar({ setToken }) {
    const [collapse, setCollapse] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const handleCollapse = () => {
        setCollapse(!collapse)
    }

    const logout = () => {
        setShowModal(true)
    }

    return (
        <div className='sticky top-0 left-0'>
            <Sidebar backgroundColor="#43C2F2" style={{ height: "100vh" }} collapsed={collapse} className="h-screen sticky top-0 left-0">
                <Menu className="pt-5" menuItemStyles={{
                    button: {
                        '&:hover': {
                            backgroundColor: '#069AD2',
                        },
                    },
                    icon: {
                        color: 'white',
                    },
                }}>
                    <MenuItem
                        icon={<i class="fi fi-br-menu-burger text-2xl"></i>}
                        onClick={handleCollapse}
                        className="hover:bg-slate-300 hover:rounded-full"
                    >
                        <Typography level="body-md">Menu</Typography>
                    </MenuItem>

                    <div className="mt-10">
                        <MenuItem icon={<i class="fi fi-sr-home text-2xl"></i>} component={<Link to="/" />}> Home </MenuItem>

                        <Typography level="body-md" className="px-5" style={{ opacity: collapse ? 0 : 0.7, letterSpacing: '0.5px' }}>User</Typography>
                        <MenuItem icon={<i class="fi fi-sr-user-add text-2xl"></i>} component={<Link to="/addUser" />}> Add User </MenuItem>
                        <MenuItem icon={<i class="fi fi-sr-users-alt text-2xl"></i>} component={<Link to="/userDetails" />}> User List </MenuItem>

                        <Typography level="body-md" className="px-5" style={{ opacity: collapse ? 0 : 0.7, letterSpacing: '0.5px' }}>Chit</Typography>
                        <MenuItem icon={<i class="fi fi-sr-hand-holding-usd text-2xl"></i>} component={<Link to="/addChit" />}> Add Chit </MenuItem>
                        <MenuItem icon={<i class="fi fi-sr-square-plus text-2xl"></i>} component={<Link to="/linkUser" />}> Link User </MenuItem>
                        <MenuItem icon={<i class="fi fi-sr-receipt text-2xl"></i>} component={<Link to="/addTransaction" />}> Add Transaction </MenuItem>
                    </div>

                    <Typography level="body-md" className="px-5" style={{ opacity: collapse ? 0 : 0.7, letterSpacing: '0.5px' }}>Settings</Typography>
                    <MenuItem icon={<i class="fi fi-sr-user-gear text-2xl"></i>} component={<Link to="/profile" />}> Profile Info </MenuItem>
                    <MenuItem icon={<i class="fi fi-bs-sign-out-alt text-2xl"></i>} onClick={() => logout()}> Logout </MenuItem>
                </Menu>
            </Sidebar>
            <Modal setToken={setToken} showModal={showModal} setShowModal={setShowModal} />
        </div>
    )
}
