import React, { useState } from "react";
import { addUser } from "../api/User";

const AddUser = () => {

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = async (event) => {
        setError("");
        setSuccess("");
        const form = event.currentTarget;
        event.preventDefault();
        var formdata = new FormData(form)
        var formDataObj = Object.fromEntries(formdata.entries())

        let response = await addUser(formDataObj)

        let userResponse = await response.json().then((r) => r)

        if (response.status === 201) {
            setSuccess(userResponse.status + '-' + userResponse.message);
        } else {
            setError(userResponse.status + '-' + userResponse.message);
        }
    }


    return (
        <div className="m-10 mt-20">
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                            First name
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="firstname"
                                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-2xl ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                            Last name
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="lastname"
                                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-2xl ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                            Number
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="number"
                                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-2xl ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                            User Type
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="usertype"
                                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-2xl ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>
                <div className='sm:col-span-6 flex justify-center items-center'>
                    <button className="bg-blue-500 h-10 mt-8 w-full md:w-96 rounded-md hover:bg-blue-700 hover:text-white shadow-xl shadow-gray-400">Add User</button>
                </div>
            </form>

            <div className="flex justify-center items-center mt-10">
                <label className="text-green-500">{success}</label>
                <label className="text-red-500">{error}</label>
            </div>
        </div>
    )
}

export default AddUser;