import * as React from 'react';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import Typography from '@mui/joy/Typography';


export default function CardInvertedColors({ chitData }) {
    return (
        <div className=''>
            <div className="flex-shrink-0 shadow-2xl overflow-hidden">
                <Card className='bg-gradient-to-r from-slate-50 to-gray-100'>
                    <CardContent orientation="horizontal">
                        <div className='flex justify-between w-full'>
                            <div className='w-2/12'>
                                <div className="bg-green-500 border-2 border-gray-950 rounded-full h-4 w-4 flex justify-center items-center text-xl text-black">

                                </div>
                            </div>
                            <div className='w-10/12 flex justify-start pl-5 text-lg font-extrabold'>
                                <Typography level="body-md"><div className='text-black'>{chitData.chitname}</div></Typography>
                            </div>
                        </div>

                    </CardContent>

                    <div className='text-black w-full bg-white shadow-inner pl-16 h-8 rounded-sm flex items-center'>
                        <CardContent orientation="horizontal">
                            <i className="fi fi-sr-coins"></i>
                            <Typography level="h6">{chitData.amount}</Typography>
                        </CardContent>
                    </div>
                    <div className='flex'>
                        <CardContent orientation="horizontal">
                            <div className='w-6/12 flex flex-col'>
                                <label className='text-sm'> Monthly </label>
                                <label className='text-xl'>{chitData.installment}</label>
                            </div>
                            <div className='w-6/12 flex flex-col'>
                                <label className='text-sm'> Months </label>
                                <label className='text-xl'>{chitData.tenure}</label>
                            </div>
                        </CardContent>
                    </div>
                    <div className='flex'>
                        <CardContent orientation="horizontal">
                            <div className='w-6/12 flex flex-col'>
                                <label className='text-sm'> StartDate </label>
                                <label className='text-xl'>{chitData.startDate}</label>
                            </div>
                            <div className='w-6/12 flex flex-col'>
                                <label className='text-sm'> EndDate </label>
                                <label className='text-xl'>{chitData.endDate}</label>
                            </div>
                        </CardContent>
                    </div>
                    <CardActions>
                        <Button className='flex w-full justify-center rounded-md px-3 py-1.5 bg-gradient-to-r from-emerald-600 to-emerald-200 text-sm font-semibold leading-6 hover:bg-gradient-to-tr hover:text-black'>
                            Details
                        </Button>
                    </CardActions>
                </Card>
            </div>
        </div>
    );
}