const baseURL = process.env.REACT_APP_API
export async function login(credentials){
    var user = null;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials)
    };
    const response = await fetch(baseURL+"/user/login",requestOptions)
    if(response.status === 200){
        user = await response.json().then((user) => user)
    }
    return user
}

export async function getUserList(){
    var userList = null;
    const response = await fetch(baseURL+"/user/getUsers")
    if(response.status === 200){
        userList = await response.json().then((userList) => userList)
    }
    return userList
}

export async function addUser(user){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    const response = await fetch(baseURL+"/user/addUser",requestOptions)
    return response
}

