import React, { useEffect } from "react";
import { useState } from "react";
import { addChit } from "../api/Chit";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function AddChit() {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const [tenure, setTenure] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const SubmitForm = async (event) => {
    setError("");
    setSuccess("");
    const form = event.currentTarget;
    
    event.preventDefault();
    var formdata = new FormData(form)
    var formDataObj = Object.fromEntries(formdata.entries())
    formDataObj["startDate"] = moment(startDate).format('DD-MMM-YYYY')
    formDataObj["endDate"] = endDate
    let response = await addChit(formDataObj)
    let userResponse = await response.json().then((r) => r)
    if (response.status === 201) {
      setSuccess(userResponse.status + '-' + userResponse.message);
    } else {
      setError(userResponse.status + '-' + userResponse.message);
    }
  }

  useEffect(() => {
    if (tenure != "" && tenure > 0 && startDate != "") {
      var endDate = moment(startDate).add(tenure - 1, 'M');
      endDate = moment(endDate).format('DD-MMM-YYYY');
      setEndDate(endDate)
    }
  }, [tenure, startDate])

  return (
    <div className="mt-10 m-10">
      <form onSubmit={SubmitForm}>
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
              Chit name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="chitname"
                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-2xl ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
              Amount
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="amount"
                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-2xl ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
              Tenure
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="tenure"
                onChange={(e) => setTenure(e.target.value)}
                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-2xl ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
              Installment
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="installment"
                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-2xl ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
              Start Date
            </label>
            <div className="mt-2">
              <DatePicker
                showIcon
                className="block w-full pl-26 rounded-md border-0 py-1.5 text-gray-900 shadow-2xl ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd-MMM-yyyy"
                isClearable
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
              End Date
            </label>
            <div className="mt-2">
              <input
                disabled
                name="endDate"
                value={endDate}
                className="block w-full rounded-md border-0 pl-4 pr-3 py-1.5 text-gray-900  bg-gray-300 shadow-2xl ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 hover:cursor-pointer"
              />
            </div>
          </div>

          <div className='sm:col-span-6 flex justify-center items-center'>
            <button className="bg-blue-500 h-10 mt-8 w-full md:w-96 rounded-md hover:bg-blue-700 hover:text-white shadow-xl shadow-gray-400">Add Chit</button>
          </div>
        </div>
      </form>
      <div className="flex justify-center items-center mt-10">
        <label className="text-green-600">{success}</label>
        <label className="text-red-500">{error}</label>
      </div>
    </div>
  )
}