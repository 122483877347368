import React from "react";

const AddTransaction = () => {
    return (
        <div class="flex flex-col items-center justify-center h-screen px-4">
            <h1 class="text-4xl font-bold text-gray-800 mb-4">Coming Soon</h1>
            <p class="text-gray-600 text-lg mb-8">We're working hard to bring you an amazing new website. Stay tuned!</p>
        </div>
    )
}

export default AddTransaction;