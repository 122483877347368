import React, { useEffect, useState } from "react";
import NavBar from "../components/Navbar";
import MUICards from "../components/MUICards";
import { getChits } from "../api/Chit";
import { Container, Button, darkColors, lightColors } from 'react-floating-action-button';
import { useNavigate } from "react-router-dom";

export default function Home() {

    const [chitList, setChitList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchChitList() {
            let response = await getChits()
            setChitList(response)
        }
        fetchChitList()
    }, [])

    return (
        <div>
            <div className="flex items-center h-auto w-full justify-center">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 mt-4">
                    {chitList.map((data, id) => {
                        return <MUICards chitData={data} />
                    })}
                </div>
            </div>
        </div>

    )
}



