import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import logo2 from "../img/logo2.png";
import useToken from "../util/TokenUtil";
import Sidebar from "../components/SideBar";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NavBar() {

  const navigate = useNavigate();
  const { token, setToken } = useToken()

  const logout = () => {
    setToken("")
    navigate('/')
    window.location.reload(false);
  }

  return (
    <div style={{backgroundColor:"#43C2F2"}} className="shadow-xl sticky top-0 z-10">
      <div className="mx-auto max-w-8xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">

          <div className="flex flex-1 mr-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <img
                className="h-20 w-20 mt-2"
                src={logo2}
                alt="Your Company"
              />
            </div>
          </div>
          <div>
            <label className="text-white text-2xl">Hello {token.firstname}</label>
          </div>
        </div>
      </div>
    </div>
  )
}
