import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import LoginPage from "./pages/LoginPage";
import Home from './pages/HomePage';
import useToken from './util/TokenUtil';
import HomeLayout from './layouts/HomeLayout';
import AddUser from './pages/AddUserPage';
import AddChit from './pages/AddChit';
import UserDetailsPage from './pages/UserDetails';
import LinkUser from './pages/LinkUserPage';
import Profile from './pages/Profile';
import AddTransaction from './pages/AddTransaction';


function App() {
  const { token, setToken } = useToken();

  if (!token) {
    return <LoginPage setToken={setToken} />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<HomeLayout setToken={setToken}/>}>
          <Route path="/" element={<Home />} />
          <Route path="/userDetails" element={<UserDetailsPage />} />
          <Route path="/addUser" element={<AddUser />} />
          <Route path="/addChit" element={<AddChit />} />
          <Route path="/linkUser" element={<LinkUser />} />
          <Route path="/addTransaction" element={<AddTransaction />} />
          <Route path="/profile" element={<Profile token={token}/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
