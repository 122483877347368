import React from "react";
import SearchDropdown from "../components/SearchDropdown";
import { useState, useEffect } from "react";
import { getChits, linkUser } from "../api/Chit";
import { getUserList } from "../api/User";

const LinkUserPage = () => {

    const [chitList, setChitList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [balanceAmount, setBalanceAmount] = useState();

    const [selectedChit, setSelectedChit] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [userAmount, setUserAmount] = useState();

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        async function fetchChitList() {
            let response = await getChits()
            setChitList(response)
        }
        fetchChitList()
        async function fetchUserlist() {
            let response = await getUserList()
            setUserList(response)
        }
        fetchUserlist()
    }, [])

    const onChitSelected = (event) => {
        let filteredChit = chitList.filter(chit => chit.chitname === event.target.value)[0]
        setBalanceAmount(filteredChit.balanceAmount)
        setSelectedChit(filteredChit)
    }

    const linkUserToChit = async () => {
        let linkUserRequest = {
            "chitname": selectedChit.chitname,
            "amount": userAmount,
            "userId": selectedUser
        }
        console.log(linkUserRequest)
        let response = await linkUser(linkUserRequest)
        let linkUserResponse = await response.json().then((r) => r)
        if (response.status === 200) {
            setSuccess(linkUserResponse.status + '-' + linkUserResponse.message);
        } else {
            setError(linkUserResponse.status + '-' + linkUserResponse.message);
        }
    }

    return (
        <div class="flex flex-col h-screen px-4">
            <select className='bg-gray-50 border mt-10 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                onChange={(e) => onChitSelected(e)}>
                <option value="" disabled selected>Select Chit</option>
                {chitList.map((option) => (
                    <option className='flex justify-center items-center pl-5'>{option.chitname}</option>
                ))}
            </select>
            {selectedChit &&
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-10">
                    <div class="sm:col-span-3 bg-gray-50 p-5 border border-gray-300 text-gray-900 text-sm rounded-lg">Amount : <span className="text-sm">{selectedChit.amount}</span></div>
                    <div class="sm:col-span-3 bg-gray-50 p-5 border border-gray-300 text-gray-900 text-sm rounded-lg">Tenure : <span className="text-sm">{selectedChit.tenure}</span></div>
                    <div class="sm:col-span-3 bg-gray-50 p-5 border border-gray-300 text-gray-900 text-sm rounded-lg">Installment : <span className="text-sm">{selectedChit.installment}</span></div>
                    <div class="sm:col-span-3 bg-gray-50 p-5 border border-gray-300 text-gray-900 text-sm rounded-lg">BalanceAmount : <span className="text-sm">{balanceAmount}</span></div>
                </div>
            }
            <select className='bg-gray-50 mt-10 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                onChange={(e) => setSelectedUser(e.target.value)}>
                <option value="" disabled selected>Select User</option>
                {userList.map((option) => (
                    <option className='flex justify-center items-center pl-5' value={option.id}>{option.firstname} - {option.number}</option>
                ))}
            </select>
            <div className="mt-10">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                        <label htmlFor="desired-amount" className="block text-sm font-medium leading-6 text-gray-900">
                            User Amount
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                name="useramount"
                                className="block w-full rounded-md border-0 pl-4 py-1.5 text-gray-900 shadow-2xl ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) => setUserAmount(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='sm:col-span-6 flex justify-center items-center'>
                    <button className="bg-blue-500 h-10 mt-8 w-full md:w-96 rounded-md hover:bg-blue-700 hover:text-white shadow-xl shadow-gray-400" onClick={linkUserToChit}>Link User</button>
                </div>
                <div className="flex justify-center items-center mt-10">
                    <label className="text-green-600">{success}</label>
                    <label className="text-red-500">{error}</label>
                </div>
            </div>
        </div>

    )
}

export default LinkUserPage;